import React, { FC } from "react";

const Page: FC = ({ children }) => {
  return (
    <main>
      <div className="max-w-7xl mx-auto py-8 px-8">{children}</div>
    </main>
  );
};

export default Page;
